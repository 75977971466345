.interactive-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at var(--mouse-x) var(--mouse-y),
    rgba(0, 240, 255, 0.15) 0%,
    rgba(127, 0, 255, 0.15) 25%,
    rgba(0, 0, 0, 0) 50%
  );
  pointer-events: none;
  z-index: 1;
}

.interactive-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(45deg, rgba(0, 240, 255, 0.1) 0%, transparent 100%),
    linear-gradient(-45deg, rgba(127, 0, 255, 0.1) 0%, transparent 100%);
  filter: blur(30px);
  transform: translateZ(0);
}

/* Add a subtle grid pattern */
.interactive-bg::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: 50px 50px;
  mask-image: radial-gradient(circle at center, black, transparent 80%);
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 0.3s ease-out forwards;
}

@keyframes distortion {
  0% {
    clip-path: polygon(0 2%, 100% 0, 100% 100%, 0 98%);
  }
  50% {
    clip-path: polygon(0 0, 100% 2%, 100% 100%, 0 98%);
  }
  100% {
    clip-path: polygon(0 2%, 100% 0, 100% 100%, 0 98%);
  }
}

.distorted-bg {
  animation: distortion 5s infinite;
}
